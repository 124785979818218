import React from "react";
import { IoMdCheckmark } from "react-icons/io";
import { COLORS } from "../../../lib/constants/Colors";

interface Props {
  number;
  title;
  description;
}

export default function (props: Props) {
  return (
    <div className="flex  md:h-48">
      <div className="w-1/6 hidden md:block pt-6 md:border-t-2 font-semibold border-primary-500 text-xl text-primary-500">
        {props.number}
      </div>
      <div className="w-full md:w-2/6 pt-6 md:border-t-2 font-semibold border-gray-600 text-xl ml-3">
                <span className="text-primary-500 mr-2 inline md:hidden">{props.number}</span>{props.title}
        <div className=" pt-2 text-sm block md:hidden text-gray-600">
        {props.description}
      </div>
      </div>
      <div className="w-3/6  hidden md:block pt-6 border-t-2 border-gray-600 text-sm text-gray-600">
        {props.description}
      </div>
    </div>
  );
}
