import React from "react";
import { FiArrowRight } from "react-icons/fi";
import styled from "styled-components";

const Box = styled.div.attrs({
  className:
    "w-full flex flex-row md:flex-col bg-black md:w-1/3 xl:w-64 leading-relaxed shadow p-5 rounded-l-sm bg-white",
})`
  background-color: rgb(10, 10, 10);
`;

export default function () {
  return (
    <div className="flex w-full container-lg justify-between flex-wrap xl:flex-no-wrap">
      <Box>
        <div className="rounded-full md:w-16 w-1/2 lg:w-full  font-bold mb-4 border-primary-500 h-auto flex justify-center items-center">
          <img
            src={require("../../../assets/icons/home/1.png")}
            className="h-auto w-16"
          />
        </div>
        <div>
          <div className="font-semibold lg:w-full lg:text-center">Newest Technology</div>
          <div className="text-sm mt-2  text-gray-600 lg:w-full lg:text-center">
            All aspects of technology assets including, purchasing,
            maintaince, and installment.
            </div>
        </div>
      </Box>
      <Box>
        <div className="rounded-full md:w-16 w-1/2 lg:w-full font-bold mb-4 border-primary-500 h-auto flex justify-center items-center">
          <img
            src={require("../../../assets/icons/home/2.png")}
            className="h-auto w-16"
          />
        </div>
        <div>
          <div className="font-semibold lg:w-full lg:text-center">The Best Team</div>
          <div className="text-sm mt-2  text-gray-600 lg:w-full lg:text-center">
            Extremely selective group of elite professionals in the field are
            available 24/7.
            </div>
        </div>
      </Box>
      <Box>
        <div className="rounded-full md:w-16 w-1/2 lg:w-full font-bold mb-4 border-primary-500 h-auto flex justify-center items-center">
          <img
            src={require("../../../assets/icons/home/3.png")}
            className="h-auto w-16"
          />
        </div>
        <div className="">
          <div className="font-semibold lg:w-full lg:text-center">Lowest Prices</div>
          <div className="text-sm mt-2  text-gray-600 lg:w-full lg:text-center">
            Competitive dynamic assesments, large scale discounts on local
            bulk installations
            </div>
        </div>
      </Box>
    </div>
  );
}
