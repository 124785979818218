import React from "react";
import "./styles.scss";
import _ from "lodash";
import styled from "styled-components";

interface Props {
  label?;
  onChange;
  name?;
  value;
  type?;
  placeholder?;
  error?;
  inputProps?;
  containerClassName?;
  prefix?;
  inputRef?;
}

const Input = styled.input.attrs({
  className: "py-2 px-2 rounded-sm w-full",
})`
  border: none;
  outline: none;
  &::placeholder {
    font-size: 0.9rem;
  }
`;

const PrefixContainer = styled.div.attrs({
  className: "",
})``;

const InputContainer = styled.div.attrs({
  className: "flex flex-row items-center w-full border-2 rounded",
})<{ hasError }>`
  border: ${(props) =>
    props.hasError ? "1px solid red" : "1px solid lightgrey"};
`;
export const InputField = (props: Props) => {
  const onChange = ({ target: { value } }) => {
    if (props.onChange) {
      if (!!props.name) {
        return props.onChange({ target: { value, name: props.name } });
      }
      return props.onChange(value);
    }
  };
  return (
    <div className={"w-full  relative " + props.containerClassName}>
      {props.label && (
        <label className={"font-medium text-sm"}>{props.label}</label>
      )}
      <InputContainer hasError={props.error}>
        <PrefixContainer>{props.prefix && props.prefix()}</PrefixContainer>
        <Input
          disabled={false}
          name={props.name}
          onChange={onChange}
          value={props.value}
          type={props.type}
          placeholder={props.placeholder}
          {...props.inputProps}
        />
      </InputContainer>
      {props.error && _.isString(props.error) && (
        <p style={{ color: "red", fontSize: "0.8rem" }}>{props.error}</p>
      )}
    </div>
  );
};
