import React, { useState } from "react";
import PageBanner from "../../components/PageBanner";
import CheckLine from "../../components/CheckLine";
import TableElement from "./TableElement";
import Fade from "react-reveal/Fade";
interface Props {}

const table = [
  {
    title: "Our Crew",
    description:
      "Our cable placement division provides telecommunications/fiber optic cableplacement underground or overhead in the power space or the communication space as well as cable splicing, testing, and troubleshooting services.",
  },
  {
    title: "Installation",
    description:
      "We provide fiber optics cable installment in the power and communication infrastructure. We have crews on call for a quick response and emergency repairs with extensive background in troubleshooting networks.Addressing issues stemming from damage to cables, damage to fibers, high loss splices, high loss connectors and more; uncovering processes that have disrupted, or may continue to hinder thenormal functioning of the network. ",
  },
  {
    title: "Extra Services",
    description:
      "Moreover, we specialize in installing outdoor optical fiber cabinets through our broadband network specialists. Our specialists will handle the process of installing and supporting the cables while avoiding common issues such as stressing and over bending cables. Every cable will have values for minimum bend radius and maximum tensile loading. All transition points, such as those going from conduit topull box or exiting the conduit, will be kept smooth.",
  },
  {
    title: "Maintaince",
    description:
      "Yanar will also be in charge of maintenance. Unlike any of our competitors, we have a competitive edge due to our solid know-how and experience in satisfying the safety requirements of working on the electricity over-headlines and electrical substations.",
  },
  {
    title: "Pricing",
    description:
      "Yanar provides operational solutions for lower prices and greater efficiency whether your requirements are short-termed or long-termed. ",
  },
];

export default function About(props: Props) {
  return (
    <div className=" text-white">
      <PageBanner
        filter={0.25}
        src={require("../../assets/images/banner/4.jpg")}
      />
      <div className="relative">
        <div className="absolute inset-0 overflow-hidden">
          <img
            style={{
              opacity: 0.05,
            }}
            src={require("../../assets/images/backgrounds/5.png")}
            className="h-auto w-full"
          />
          <img
            style={{
              opacity: 0.05,
            }}
            src={require("../../assets/images/backgrounds/5.png")}
            className="h-auto w-full"
          />
        </div>
        <div className="mt-4 container-lg text-3xl header_text pt-24 pb-4">
          What seperates us from our competitors
        </div>

        <div className="font-semibold text-xl text-primary-500 container-lg ">
          Overview
        </div>
        <div className="container-lg flex flex-col md:flex-row pb-4">
          <div className="w-full md:w-1/2">
            <div className="pt-2">
              Yanar is a Technological Implementations company aiming in
              developing businesses through applying day-to-day updated
              technological solutions that will match your specific business
              needs with the adequate expertise through top-notch qualified
              professionals.
            </div>
          </div>
          <div className=" pt-4 md:pt-0 md:ml-auto">
            <CheckLine text="Project completion on time regardless of volume size." />
            <CheckLine text="Provided by the highest experts in the field." />
            <CheckLine text="The fastest growing fiber optics technology provider." />
          </div>
        </div>

        <div className="pt-4 pb-10 md:pt-16 container-lg">
          {table.map((e, index) => (
            <Fade top>
              <TableElement
                number={"0" + (index + 1).toString()}
                title={e.title}
                description={e.description}
              />
            </Fade>
          ))}
        </div>
      </div>
    </div>
  );
}
