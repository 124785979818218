import React, { useRef, useEffect, useState } from "react";
import Slider from "react-slick";
import Testimony from "../../../lib/types/ITestimony";
import PersonItem from "./PersonItem";
import { FiArrowRight, FiArrowLeft } from "react-icons/fi";
import { COLORS } from "../../../lib/constants/Colors";
import "./styles.css";
import useTyper from "../../../hooks/useTyper";

export default function () {
  const Testimonies: Array<Testimony> = [
    {
      picture:
        "https://i.ibb.co/BPFp4gW/gaga.png",
      description:
        "If you're looking for a rewarding career and a chance to make an impact, you've come to the right place, We will transform your needs through our techniques!",
      job: "Opertional Manager",
      name: "Mohamad Allowh",
    },
    {
      picture: "https://i.ibb.co/pr7RzXY/guy-2.png",
      description:
        "YanarTech has earned our business for our grind & polish needs. From purchasing consumables to consulting on a particular issue & servicing it.",
      job: "IT Specialist",
      name: "George Marashilian",
    },
    {

      picture: "https://i.ibb.co/TT2CSNX/guy3.png",
      description:
        "Well, thanks to the efforts of Fanous and whoever does the shipping in your company, we received the product the next day early am on time.",
      job: "Poet & Thinker",
      name: "Tala Ma'ayah",
    },
    {
      picture: "https://i.ibb.co/84wkH6m/girl.png",

      description:
        "This is a cool company, and I suggest you work with them because they bring gifts.",
      job: "Technical Co-founder",
      name: "Ziad Abu Saleh",
    },
  ];

  const NextButton = ({ callBack }) => (
    <div
      style={{ zIndex: 100 }}
      onClick={callBack}
      className="cursor-pointer text-primary-500 ml-2 text-semibold flex items-center"
    >
      <div className="mr-2">Next</div>
      <FiArrowRight size={20} color={COLORS.PRIMARY} />
    </div>
  );

  const PrevButton = ({ callBack }) => (
    <div
      style={{ zIndex: 100 }}
      onClick={callBack}
      className="cursor-pointer ml-2 text-semibold flex items-center"
    >
      <FiArrowLeft size={20} />
      <div className="ml-2">Prev</div>
    </div>
  );
  const testSlider = useRef<any>(null);
  const [currentSlide, setCurrectSlide] = useState(0);

  const settings = {
    ref: testSlider,
    initial: 0,
    dots: false,
    arrows: false,
    infinite: true,
    speed: 500,
    swipe: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const { typer, writeText, deleteText, isTyping } = useTyper({
    writeSpeed: 35,
    deleteSpeed: 2,
    extractor: (slide) => Testimonies[slide].description,
    text: () => Testimonies[getCurrentSlide()].description,
  });

  useEffect(() => {
    writeText();
  }, []);

  const SlideNext = () => {
    if (isTyping) {
      return;
    }
    deleteText(() => {
      let cal = (currentSlide + 1) % Testimonies.length;
      setCurrectSlide(cal);
      setTimeout(()=>{
        testSlider.current.slickNext();
      },500)
      setTimeout(() => {
        writeText(null, cal);
      }, 1000);
    });
  };
  const SlidePrev = () => {
    if (isTyping) {
      return;
    }
    deleteText(() => {
      let cal = currentSlide - 1 < 0 ? Testimonies.length - 1 : currentSlide - 1;
      setCurrectSlide(
        cal
      );
      setTimeout(()=>{
        testSlider.current.slickPrev();
      },500)
      setTimeout(() => {
        writeText(null, cal);
      }, 1000);
    });
  };
  const getCurrentSlide = () => {
    return currentSlide;
  }
  return (
    <div
      // key={currentSlide}
      style={{
        zIndex: 100,
      }}
      className="pb-16"
    >
      <div className="pb-2 block xl:hidden flex justify-center left-0">
        <img
          src={require("../../../assets/images/quote/img.png")}
          className="h-16 w-auto"
        />
      </div>
      <div className="container-lg flex justify-center relative">
        <div
          style={{ top: -10, left: -30 }}
          className="absolute hidden xl:block  left-0"
        >
          <img
            src={require("../../../assets/images/quote/img.png")}
            className="h-16 w-auto"
          />
        </div>
        <div className="">
          <div className="text-center py-2 text-primary-500 font-bold text-regular md:text-lg">
            1500 Satified users over the Globe
          </div>

          <div
            id={Testimonies[getCurrentSlide()].description}
            style={{}}
            className="text-md sm:text-xl md:text-2xl lg:text-3xl  h-32  tracking-wide overflow-hidden px-16 text-center md:text-justify"
          >
            {typer}
          </div>
        </div>
      </div>
      <div className="container-lg pt-6 flex justify-center items-center">
        <PrevButton callBack={SlidePrev} />
        <div className="dynamic_width" style={{ marginLeft: 10 }}>
          <Slider {...settings}>
            {Testimonies.map((t) => (
              <PersonItem data={t} />
            ))}
          </Slider>
        </div>
        <NextButton callBack={SlideNext} />
      </div>
      <div className="flex justify-center flex-col items-center pt-4">
        <div className="font-semibold lg:text-xl">
          {Testimonies[getCurrentSlide()].name}
        </div>
        <div className="text-sm">{Testimonies[getCurrentSlide()].job}</div>
      </div>
    </div>
  );
}
