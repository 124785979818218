import React from "react";
import Loading from "../../components/Loading";
import styled from "styled-components";
import CheckLine from "../../components/CheckLine";
import Section from "./Section";

interface Props {}

export default function Solutions(props: Props) {
  const sps = [
    {
      icon: require("../../assets/icons/special/1.png"),
      title: "Building concrete/steel base substations if required",
      desc:
        "When a site seeing is completed by our architects and engineers, the equipment foundations will be installed. We specialize in reinforced concretethat may be drilled in pier or spread footing design forms. Following the installation of the foundations, the substation equipment is delivered and installed at the station. After the equipment is installed, a finish layer of crushed stone is installed over the entire station up to the base of the structure foundations giving the station a finished look.",
    },
    {
      icon: require("../../assets/icons/special/2.png"),
      title: "Fixing/installing cabinets on base/floor and grounding it.",
      desc:
        "Yanar will ground and maintain cabinet housing through the protection of network components from external weather conditions and any will fulfil other maintenance requirements. We recommend these cabinets to be used inthe classic copper transmission application as well as in modern fiber technology.",
    },
    {
      icon: require("../../assets/icons/special/3.png"),
      title: "Optical fiber cable splicing in street cabinets.",
      desc: (
        <div>
          Optical fiber cable splicing through a fusion splicing machine (Name
          the machine)
          <div>
            <CheckLine text="Cable Stripping " />
            <CheckLine text="Cable Cleaving" />
            <CheckLine text="Cable Fusing " />
            <CheckLine text="Cable Protecting " />
          </div>
        </div>
      ),
    },
    {
      icon: require("../../assets/icons/special/4.png"),
      title: "Patching",
      desc:
        "Choosing between direct cross connection and patch panels, then arranging the routing and dressing of the fiber patch cords if fiber panels are chosen. There is also the option of using fiber cable management brackets to avoid the dangling fiber patch cables.",
    },

    {
      icon: require("../../assets/icons/special/5.png"),
      title: "Fiber Testing",
      desc: (
        <div>
          Using a combination of one or more of the following options:
          <div className="text-left">
            <CheckLine text="Optical inspection microscope." />
            <CheckLine text="Source and power meter, optical loss test set (OLTS) or test kit with proper equipment adapters for the tested cable plant." />
            <CheckLine text="Reference test cables that match the cables to be tested and mating adapters, including hybrids if needed." />
            <CheckLine text="Fiber Tracer or Visual Fault Locator." />
            <CheckLine text="Cleaning materials - dry cleaning kits or lint free cleaning wipes and pure alcohol." />
            <CheckLine text="OTDR with launch and/or receive cables for troubleshooting." />
          </div>
        </div>
      ),
    },
    {
      icon: require("../../assets/icons/special/6.png"),
      title: "Documentation and Labeling.",
      desc:
        "All processes and used equipment will be documented and labeled for your demand and will be used for future reference and evaluation.",
    },
  ];

  return (
    <div className="py-12 container-lg">
      <div
        style={{
          opacity: 0.04,
          zIndex: -100,
        }}
        className="absolute inset-0"
      >
        <img
          src={require("../../assets/images/backgrounds/7.png")}
          className="h-full w-full"
        />
      </div>
      <div className="text-primary-500 w-full text-center font-bold text-sm">
        Nationwide service, Local & international expertise 
      </div>
      <div className="pb-10 w-full text-center pt-4 font-bold header_text text-white text-xl sm:text-2xl md:text-3xl lg:text-4xl">
        Our crew is a highly trained and dedicated <br /> to offer the highest quality of services{" "}
        <br />
        in the field!
      </div>
      '
      <div className="text-gray-200 flex flex-col md:flex-row">
        <div className="w-full md:pr-8 md:border-r border-gray-900 opacity-100 p-2 rouned-sm md:w-1/2">
          <Section data={sps[0]} />
          <Section data={sps[1]} />
          <Section data={sps[2]} />
        </div>
        <div className="w-full hidden md:block md:pl-8  opacity-100 p-2 rouned-sm md:w-1/2">
          <Section right data={sps[3]} />
          <Section right data={sps[4]} />
          <Section right data={sps[5]} />
        </div>
        <div className="w-full md:hidden md:pl-8  opacity-100 p-2 rouned-sm md:w-1/2">
          <Section data={sps[3]} />
          <Section data={sps[4]} />
          <Section data={sps[5]} />
        </div>
      </div>
    </div>
  );
}
