import React from "react";
import styled from "styled-components";

const Card = styled.div.attrs({ className: "w-full height_controller px-4" })`
  height: 400px;
  @media screen and (max-width: 1024px) {
    height: auto;
  }
`;

export default function ({ data }: any) {
  return (
    <Card>
      <div 
      className="pl-8 overflow-y-auto flex h-full justify-center flex-row lg:flex-col items-center lg:items-start border border-gray-800">
        <div className="h-16 w-auto sm:w-16">
          <img src={data.icon} className="w-auto"/>
        </div>

        <div className="ml-10 lg:ml-0">
          <div className="mt-8 font-semibold text-white text-lg header_text leading-relaxed tracking-wide">
            {data.title}
          </div>
          <div className="text-gray-400 mt-4 text-sm leading-relaxed text-justify mr-4 lg:mr-12">
            {data.desc}
          </div>
          <div className="text-white font-bold mt-6 mb-8 lg:mb-0">
            Learn more
          </div>
        </div>
      </div>
    </Card>
  );
}
