import React, { useState } from "react";
import Carasouel from "./Carasouel";
import data from "./data";
import LightBox from "./LightBox";
import Fade from "react-reveal/Fade";

import "./styles.css";
interface Props {}

const images = data.map((i) => i.image);
export default function Gallery(props: Props) {
  const [open, setOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const onImageClick = (index) => {
    setPhotoIndex(index);
    setOpen(true);
  };
  return (
    <div className="height_support">
      <div className="hidden lg:block">
        <Carasouel />
      </div>
      <div className="lg:hidden text-white text-xl font-semibold p-2 pt-4">
        Gallery
      </div>
      <div className="lg:hidden flex flex-wrap p-1">
        {data.slice(0, 10).map((slide, index) => {
          return (
              <Fade top cascade>
                <div
                  onClick={() => onImageClick(index)}
                  className="overflow-hidden relative h-64 w-full cursor-pointer shadow rounded-sm m-2"
                >
                  <div
                    style={{
                      backgroundImage: `url(${slide.image})`,
                      backgroundRepeat: "none",
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      backgroundColor: "rgba(0,0,0,0.05)",
                    }}
                    className="h-full w-full rounded-sm overflow-hidden"
                  />
                  {/* <div className="absolute h-full inset-0 bg-gray-900 opacity-50"></div> */}
                  <div className="absolute p-2 pl-3 inset-0">
                    <div className="text-white text-semibold text-2xl">
                      {slide.title}
                    </div>
                    <div className="text-white text-sm">{slide.subtitle}</div>
                  </div>
                </div>
              </Fade>
          );
        })}
      </div>
      <LightBox
        photoIndex={photoIndex}
        setPhotoIndex={setPhotoIndex}
        open={open}
        images={images}
        setOpen={setOpen}
      />
    </div>
  );
}

