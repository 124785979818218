import Main from "../layouts/Main";
import Home from "../pages/Home";
import React from "react";
import Objectives from "../pages/Objectives";
import Solutions from "../pages/Solutions";
import About from "../pages/About";
import Gallery from "../pages/Gallery";
import SmartSolutions from "../pages/Partners";
import ContactUs from "../pages/ContactUs";
import QR from "../pages/QR";
import TermsandConditions from "../pages/App/TermsandConditions";
import PrivacyPolicy from "../pages/App/PrivacyPolicy";
import StoreSupport from "../pages/StoreSupport";
// import Partners from "../pages/Home/Partners";
export default [
  {
    path: "/*",
    element: <Main />,
    children: [
      {
        path: "",
        element: <Home />,
      },
      {
        path: "/objectives",
        element: <Objectives />,
      },
      {
        path: "/solutions",
        element: <Solutions />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/gallery",
        element: <Gallery />,
      },
      // {
      //   path: "/partners",
      //   element: <Partners />,
      // },
      {
        path: "/contact",
        element: <ContactUs />,
      },
    ],

  },
  {
    path: "/QR",
    element: <QR />,
  },
  {
    path: "/store/terms_and_conditions",
    element: <TermsandConditions />,
  },
  {
    path: "/store/privacy_policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "/store/support",
    element: <StoreSupport />,
  },
];
