import React from "react";
import "./styles.css";
import {useNavigate} from "react-router-dom";
interface Props {
  display;
  path;
}

export default function (props: Props) {
  const navigate = useNavigate()
  return (
    <li onClick={()=>navigate(props.path)} className="routeContainer text-white px-3">
      <a className="cool-link">{props.display}</a>
    </li>
  );
}
