import React from "react";
import { useNavigate } from "react-router-dom";
import PageBanner from "../../components/PageBanner";
import styled from "styled-components";
import Card from "./Card";
import { FaArrowRight } from "react-icons/fa";
import { IoIosArrowRoundForward } from "react-icons/io";
interface Props {}

const Wrapper = styled.div.attrs({ className: "w-full lg:w-1/3" })`
  &:first-child {
  }

  
`;

// &:nth-child(2) {
//   margin-top: 1rem;
//   @media screen and (max-width: 1024px) {
//     margin-top: 0rem;
//   }
// }
// &:last-child {
//   margin-top: 2rem;
//   @media screen and (max-width: 1024px) {
//     margin-top: 0rem;
//   }
export default function Objectives(props: Props) {
  const navigate = useNavigate();
  const objectives = [
    {
      title: "Growth",
      desc:
        "Increase our current customer satisfaction and trust through broadcasting the company's brand identity",
      icon: require("../../assets/icons/objectives/5.png"),
    },
    {
      title: "Expantion",
      desc:
        "Expand our business through supporting new clients worldwide & developing new partnerships.",
      icon: require("../../assets/icons/objectives/7.png"),
    },

    {
      title: "Maintenance",
      desc:
        "YanarTech will also provide maintaince. we have an edge & solid-know-how in satisfying the customer regulations and requirements.",
      icon: require("../../assets/icons/objectives/1.png"),
    },
    {
      title: "Training",
      desc:
        "Upsurge our current employees’ knowledge and skills to further add value to our clients through expertise and differentiation.",
      icon: require("../../assets/icons/objectives/2.png"),
    },

    {
      title: "Recruitment",
      desc:
        "Hire more employees from selected groups and advanced skillsets and and allow them to professionally train by experts.",
      icon: require("../../assets/icons/objectives/4.png"),
    },

    {
      title: "Research & Development",
      desc:
        "Increase the value of our business and services through research and development & following world-wide trends.",
      icon: require("../../assets/icons/objectives/3.png"),
    },
  ];
  return (
    <div className="relative pb-16">
      {/* <PageBanner

      filter={0.25}
        src={require("../../assets/images/banner/1.jpg")}
      /> */}
      <div className="pt-8 text-primary-500 w-full text-center font-bold text-sm">
        Nationwide service, Local & international expertise
      </div>
      <div className="mt-2 text-white container-lg text-4xl w-full text-center py-4 font-black header_text">
        Our Objectives
      </div>
      <div
        style={{
          opacity: 0.035,
          zIndex: -100,
        }}
        className="absolute inset-0"
      >
        <img
          src={require("../../assets/images/backgrounds/7.png")}
          className="h-full w-full"
        />
      </div>
      <div className="pt-4 container-xl">
        <div className="flex flex-wrap">
          <Wrapper>
            <Card data={objectives[0]} />
          </Wrapper>
          <Wrapper>
            <Card data={objectives[1]} />
          </Wrapper>
          <Wrapper>
            <Card data={objectives[2]} />
          </Wrapper>
        </div>

        <div className="lg:mt-8 flex flex-wrap">
          <Wrapper>
            <Card data={objectives[3]} />
          </Wrapper>
          <Wrapper>
            <Card data={objectives[4]} />
          </Wrapper>
          <Wrapper>
            <Card data={objectives[5]} />
          </Wrapper>
        </div>
      </div>
      <div className="flex flex-col w-full lg:flex-row text-white container-xl pt-16">
        <div className="w-full lg:w-1/2 pl-4">
         Trusted by leading organizations to support and hand deliver
           <br />
           the best services in fiber and infrastructure technologies
          <br />
          in a timely and professional manner.
        </div>
        <div className="w-full lg:w-1/2  flex items-center justify-start lg:justify-end pl-4 lg:pl-0 lg:pr-4 pt-4">
          <div
            onClick={() => navigate("/solutions")}
            className="button-primary h-10 w-48 text-xl flex justify-center items-center font-semibold rounded-md"
          >
            Find Your Solution
          </div>
          <div
            onClick={() => navigate("/objectives")}
            className="ml-4 cursor-pointer flex hover:opacity-75 items-center"
          >
            Explore All Services
            <div className="font-light ml-2">
              <IoIosArrowRoundForward size={25} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
