import React from "react";
import Flip from "react-reveal/Flip";

interface Props { }

export default function Partners(props: Props) {
  const Parnters = [
    {
      image: require("../../../assets/images/partners/5.png"),
      title: "Garandal",
      relation: "Sister Company"
    },
    {
      image: require("../../../assets/images/partners/7.png"),
      title: "JEPCO",
      relation: "Client"
    },
    {
      image: require("../../../assets/images/partners/6.png"),
      title: "XIAOMI",
      relation: "Franchise"
    },
    // {
    //   image: require("../../../assets/images/partners/8.png"),
    //   title: "Umnieh",
    //   relation: "Client"
    // }
  
  ];
  return (
    <div style={{}} className="h-64 md:h-48">
      <div className="w-full text-center font-semibold text-xl md:hidden">
        Our Partners
      </div>
      <div className="w-full relative h-full">
        <div
          style={{
            background:
              "linear-gradient(to left,#0A0A0A,rgb(52,110,122),#0A0A0A )",
          }}
          className="flex h-full hidden md:block opacity-100 absolute inset-0 opacit w-full "
        ></div>

        <div className="flex h-full absolute inset-0 w-full ">
          <Flip left cascade>
            <div className="w-full container-xl flex flex-wrap lg:flex-no-wrap ">
              {Parnters.map((p) => (
                <div className="w-full mx-auto lg:mx-2 md:w-1/3 p-4 flex justify-center items-center relative flex-col">
                  {/* <div
                    style={{
                      backgroundColor: "rgb(10,10,10)",
                      zIndex: 0,
                      opacity: 0.1
                    }}
                    className="absolute shadow inset-0">
                  </div> */}
                  <div style={{
                    zIndex: 2
                  }}
                  
                  >
                    <div>
                      <img src={p.image} className="h-24" />
                    </div>
                    <div className="mt-2">
                      <div className="font-semibold w-full text-center text-gray-400">
                        {p.title}
                      </div>
                      <div className="text-sm font-light w-full text-center text-gray-600">
                        {p.relation}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </Flip>
        </div>
      </div>
    </div>
  );
}
