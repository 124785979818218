import React from "react";

export default function Logo() {
  return (
    <div 
    
    style={{
      zIndex:1999999999999999
    }}
    className="text-xl font-bold">
      <span className="text-primary-500">YANAR</span> <span className="text-white">TECH</span>
    </div>
  );
}
