import React from "react";
import Banner from "./Banner";
import Testimonies from "./Testimonies";
import Boxes from "./Boxes";
import Partners from "./Partners";
import styled from "styled-components";


const Title = styled.div.attrs({ className: "w-full text-center py-4 header_text text-4xl uppercase font-semibold" })``

export default function () {
  return (
    <div
      className="text-white relative "
      style={{ background: "rgb(10,10,10)" }}
    >
      <div className="relative">
        <Banner />
        {/* 
        <div className="bg-primary-500 w-full py-4">
          <div className="text-regular xl:text-xl justify-center text-center  text-white  flex items-center h-full">
            “Any sufficiently advanced technology is indistinguishable from magic.” - Arthur C Clarke
          </div>
        </div> */}


        <div className="flex flex-col lg:mt-10 mb-10 justify-center">
          {/* <Title>
            What we do
          </Title> */}
          <Boxes />

          <div
            style={{
              opacity: 0.04,
            }}
            className="absolute hidden md:block inset-0 h-full w-full"
          >
            <img
              className="h-full w-full"
              src={require("../../assets/images/backgrounds/5.png")}
            />
          </div>
        </div>



        <div className="relative pb-32 lg:pb-12 lg:mt-16">

          <Partners />


        </div>


        <div className="relative mt-48 lg:mt-16 pb-32">
          <div>
            <Testimonies />
          </div>

          <div
            style={{
              zIndex: 0,
            }}
            className="absolute inset-0"
          >
            <img
              style={{
                opacity: 0.4,
              }}
              src={require("../../assets/images/backgrounds/9.png")}
              className="h-full w-full"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
