import React, { useState, useEffect } from "react";
import TextArea from "@atlaskit/textarea";
import Select from "@atlaskit/select";
import _ from "lodash";
import styled from "styled-components";
import { InputField as FieldInput } from "../../../components/InputField";
import { useFormik } from "formik";
import * as Yup from "yup";
interface Props {
  onSubmit;
}

const Label = styled.div.attrs({
  className:
    "flex items-center text-sm text-gray-600 text-primary-500 font-bold ",
})``;
const Row = styled.div.attrs({
  className: "flex md:mb-8 flex-col md:flex-row",
})``;

const RowInput = styled.div.attrs({
  className: "mb-3 md:mb-0 w-full md:w-1/2",
})`
  &:first-child {
    margin-right: 1em;
    @media screen and (max-width: 764px) {
      margin-right: 0em;
    }
  }
  &:first-last {
    margin-left: 1em;
    @media screen and (max-width: 764px) {
      margin-left: 0em;
    }
  }
`;
export default function Form(props: Props) {
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, "Too Short!")
      .max(50, "Too Long!")
      .required("Required"),
    phone: Yup.string().matches(
      /^[0]*7[7,8,9]+[1-9]{7}$/g,
      "Please enter a valid phone number"
    ).required("Please enter your mobile number"),
    email: Yup.string()
      .email("Please enter a valid email")
      .required("Required"),
  });
  const onSubmit = async () => {
    await validateForm()
      .then((data) => {
        console.log("errors", errors);
        if (_.isEmpty()) {
          props.onSubmit(values);
        }
      })
      .catch((e) => { });
  };

  const { handleChange, values, errors, validateForm } = useFormik({
    initialValues: {
      name: "",
      phone: "",
      email: "",
      type: { label: "Map", value: "Map" },
      description: "",
    },
    onSubmit,
    validationSchema,
    validateOnBlur: false,
    validateOnChange: false,
    isInitialValid: false,
  });

  const email_regex = RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g);
  const phone_regex = RegExp(/^[0]*7[7,8,9]+[1-9]{7}$/g);

  return (
    <div
      style={{ zIndex: 100 }}
      className="md:p-4 py-3 flex justify-center items-center h-full relative"
    >
      <div className="w-full px-3 md:px-0">
        <Row>
          <RowInput>
            <Label>
              Name <span className="ml-1 font-light text-xs">(Required)</span>
            </Label>
            <FieldInput
              name="name"
              placeholder="John Doe"
              onChange={handleChange}
              value={values.name}
              error={errors?.name}
            />
          </RowInput>
          <RowInput>
            <Label>
              Phone <span className="ml-1 font-light text-xs">(Required)</span>
            </Label>
            <FieldInput
              name="phone"
              placeholder="0792991...."
              onChange={handleChange}
              value={values.phone}
              error={errors?.phone}
            />
          </RowInput>
        </Row>
        <Row>
          <RowInput>
            <Label>
              Problem{" "}
              <span className="ml-1 font-light text-xs">(Required)</span>
            </Label>
            <div className="">
              <Select
                className="single-select"
                classNamePrefix="react-select"
                onChange={(data) =>
                  handleChange({ target: { name: "type", value: data } })
                }
                value={
                  !!values.type
                    ? values.type
                    : { label: "Map", value: "Map" }
                }
                options={[
                  { label: "Map", value: "Map" },
                  { label: "Jobs", value: "Jobs" },
                  { label: "Unit", value: "Unit" },
                  { label: "Location", value: "Location" },
                  { label: "Notifications", value: "Notification" },
                  { label: "Other", value: "Other" },
                ]}
                placeholder="Choose a City"
              />
            </div>
          </RowInput>
          <RowInput>
            <Label>
              Email
              <span className="ml-1 font-light text-xs">(Required)</span>
            </Label>
            <FieldInput
              name="email"
              placeholder="example@gmail.com"
              onChange={handleChange}
              value={values.email}
              error={errors?.email}
            />
          </RowInput>
        </Row>

        <div>
          <Label>
            Additional Details
            <span className="ml-1 font-light text-xs">(Optional)</span>
          </Label>
          <TextArea
            name="description"
            placeholder="Describe your problem.."
            onChange={handleChange}
            minimumRows={5}
            value={values.description}
          />
        </div>

        <div
          onClick={onSubmit}
          className="button-primary h-12 flex justify-center items-center text-lg font-bold mt-10"
        >
          Report Problem
        </div>
      </div>
    </div>
  );
}
