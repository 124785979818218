import React, { useState } from "react";
import { push as Menu } from "react-burger-menu";
import { GiHamburgerMenu } from "react-icons/gi";
import { useNavigate } from "react-router-dom";
//@ts-ignore
import styles from "./styles.css";
import _ from "lodash";

import MenuRoutes from "../../../lib/constants/MenuRoutes";
import Logo from "../../../components/Logo";

interface Props {}

export default function MobileHeader(props: Props) {
  const navigate = useNavigate();
  const showSettings = (event) => {
    event.preventDefault();
  };
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = _.debounce(
    () => {
      setMenuOpen(!menuOpen);
    },
    500,
    { leading: true, trailing: true }
  );

  return (
    <div
      style={{
        paddingBottom: 48,
        zIndex:1999999999999999,
        backgroundColor:"rgb(10,10,10)"
      }}
      className="sticks top-0 border-b border-gray-800 flex justify-between items-center"
    >
      <div
        onClick={toggleMenu}
        style={{ top: 12, left: 10 }}
        className="absolute"
      >
        <GiHamburgerMenu size={25} color={"white"} />
      </div>
      <Menu isOpen={menuOpen} styles={styles}>
        <div onClick={() => navigate("")} className="pb-6 cursor-pointer">
          <Logo />
        </div>
        {MenuRoutes.map((route) => (
          <a
            onClick={() => {
              toggleMenu();
              setTimeout(() => {
                navigate(route.path);
              }, 15000);
            }}
            id="home"
            className="menu-item"
            href={route.path}
          >
            {route.name}
          </a>
        ))}
        <a
          onClick={() => navigate("/contact")}
          className="primary button bg-primary-500 w-32 text-white text-center mt-4"
        >
          Contact us
        </a>
      </Menu>
      <div style={{ top: 12, right: 10 }} className=" absolute">
        <div onClick={() => navigate("")} className="cursor-pointer">
          <Logo />
        </div>
      </div>
    </div>
  );
}
