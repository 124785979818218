import React, { useState } from "react";
import axios from "axios";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import CheckLine from "../../components/CheckLine";
import Form from "./Form";
import Loading from "../../components/Loading";
import { COLORS } from "../../lib/constants/Colors";
import Map from "../../components/Map";
interface Props {}

export default function ContactUs(props: Props) {
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const sendEmail = async (data) => {
    setLoading(true);
    await axios
      .post(
        "https://api.emailjs.com/api/v1.0/email/send",
        {
          service_id: "gmail",
          template_id: "yanartech_website_query-0",
          user_id: "user_l2KCiyM1s2JDH0MiuzJmZ",
          template_params: {
            ...data,
            type: data.type.value,
          },
        },
        {
          headers: {
            contentType: "application/json",
          },
        }
      )
      .then((response) => {
        setLoading(false);
        console.log("API", response, response.data);
        setSubmitted(true);
      })
      .catch((e) => {
        setLoading(false);
        console.log("API", e);
      });
  };
  const onFormSubmit = (data) => {
    sendEmail(data);
  };
  return (
    <div className="container-lg py-16 rounded-sm overflow-hidden">
      {loading && <Loading />}
      <div className="flex p-4 md:p-0 flex-col md:flex-row">
        <div className="w-full md:w-5/12 bg-primary-500 relative">
          <div
            style={{ opacity: 0.9 }}
            className="absolute  inset-0 zindex-1 bg-primary-500 flex flex-col items-between text-white"
          >
            <div className="p-4 flex-grow-1 ">
              <div className="text-2xl font-semibold header_text">
                Excellence in fiber optics Technologies
              </div>

              <div className="pt-3">
                Yanartech has been helping customers through out jordan to
                manage their internet solutions with our unique approach to
                technology.
              </div>

              <div className=" mt-3">
                <CheckLine
                  textClass="font-semibold"
                  color="white"
                  text="Project completion on time regardless of volume size"
                />
                <CheckLine
                  textClass="font-semibold"
                  color="white"
                  text="Provided by the highest experts in the field."
                />
                <CheckLine
                  textClass="font-semibold"
                  color="white"
                  text="The fastest growing fiber optics technology provider."
                />
              </div>
            </div>
            <div className="hidden md:block border-t opacity-75 mt-auto">
              <Map />
            </div>
          </div>
          <div
            style={{
              backgroundImage: `url(${require("../../assets/images/banner/5.jpg")})`,
              backgroundRepeat: "none",
              backgroundPosition: "center",
              backgroundSize: "cover",
              backgroundColor: "rgba(0,0,0,0.05)",
              height: "60vh",
            }}
            className="w-full"
          />
        </div>
        <div className="w-full border md:w-7/12 bg-gray-300">
          {!submitted ? (
            <Form onSubmit={onFormSubmit} />
          ) : (
            <div className="w-full h-full flex flex-col justify-center items-center text-lg text-gray-900">
              <IoIosCheckmarkCircleOutline size={50} color={COLORS.PRIMARY} />
              Your request has been submitted!
              <div className="text-sm  font-semibold">Thank You</div>
            </div>
          )}
        </div>
      </div>
      <div
        style={{
          zIndex: -10,
          opacity: 0.1,
        }}
        className="absolute inset-0  border-white "
      >
        <img
          src={require("../../assets/images/backgrounds/6.png")}
          className="h-full w-full"
        />
      </div>

      <div className="md:hidden pt-12 px-4">
        <Map />
      </div>
    </div>
  );
}
