import React from "react";
import { useNavigate } from "react-router-dom"
import { FiArrowRight } from "react-icons/fi";
import { COLORS } from "../../../lib/constants/Colors";
import IMAGE from "../../../assets/images/banner/3.png";
import ReactTypingEffect from "react-typing-effect";
import Fade from "react-reveal/Fade";
import "./styles.css";
import PageBanner from "../../../components/PageBanner";
export default function () {
  const navigate = useNavigate();
  return (
    <div
      className="relative height "
      style={{
        zIndex: 10,
        backgroundColor: "rgb(10,10,10)",
      }}
    >
      <PageBanner
        src={IMAGE}
        content={
          <div className="my-auto sm:mr-24">
            <Fade left cascade>
              <div className="text-2xl text-center sm:text-left lg:text-3xl xl:text-4xl header_text">
                Fiber Optics Innovation
              </div>

              <div className="text-2xl text-center sm:text-left  ">
                Bringing Jordan Closer!
              </div>
              <div
                onClick={() => navigate("/about")}
                className="button-primary mt-4 w-32 hidden sm:block">
                Learn More
              </div>
            </Fade>
          </div>
        }
      />

      {/* <div className="zIndex-1 p-24 flex flex-col items-center justify-center text-black">
      <img
        style={{ width: "500px" }}
        src={"https://i.ibb.co/R22FGQf/Website-under.jpg"}
        className="h-auto "
      />
      <div className="text-xl mt-2">
        All Rights Reserved © Vuedale Technologies
      </div>
    </div> */}
    </div>
  );
}
