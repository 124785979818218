import React, { useEffect, useReducer, useRef, useState } from "react";
import styled, { keyframes } from "styled-components";

const blink = keyframes`
from { opacity: 1; }
to { opacity: 0; }
`;

const Cursor = styled.p.attrs((props: any) => ({ key: "s" })) <any>`
  &::after {
    content: attr(data-end);
    word-wrap: inherit;
    position: inline;
    animation-name: ${blink};
    animation-duration: 800ms;
    animation-iteration-count: infinite;
    opacity: 1;
  }
`;

export default function useTyper({ text: otherText, extractor, writeSpeed, deleteSpeed }) {

  const [isTyping, setIsTyping] = useState(false);
  function reducer(state, action) {
    switch (action.type) {
      case "inc": {
        return { ...state, C: state.C + 1 };
      }
      case "dec":
        return { ...state, C: state.C - 1 };
      case "set_typer":
        return { ...state, T: action.value };
      case "set_count":
        return { ...state, C: action.value };
      default:
        throw new Error();
    }
  }
  const initialState = { T: "", C: 0 };
  const [state, dispatch] = useReducer(reducer, initialState);

  const _state = useRef(state);

  useEffect(() => {
    // console.log("ss", state);
    _state.current = state;
  }, [state]);
  const writeText = (callBack = null, extractorKey = 0) => {
    const text = extractor(extractorKey)
    dispatch({
      type: "set_count",
      value: 0,
    });
    setIsTyping(true);
    let interval = setInterval(() => {
      dispatch({
        type: "set_typer",
        value: text.slice(0, _state.current.C),
      });
      dispatch({
        type: "inc",
      });
      if (_state.current.C > text.length) {
        clearInterval(interval);
        console.log("ggg");
        setIsTyping(false);
        callBack && callBack();
      }
    }, writeSpeed);
  };

  const deleteText = (callBack = null, extractorKey = 0) => {
    const text = extractor(extractorKey)
    dispatch({
      type: "set_count",
      value: text.length,
    });
    setIsTyping(true);
    let _interval = setInterval(() => {
      dispatch({
        type: "set_typer",
        value: text.slice(0, _state.current.C),
      });
      dispatch({
        type: "dec",
      });
      if (_state.current.C < 0) {
        clearInterval(_interval);
        setIsTyping(false);
        callBack && callBack();
      }
    }, deleteSpeed);
  };

  return {
    typer: <Cursor data-end={"|"}>{state.T}</Cursor>,
    deleteText,
    writeText,
    isTyping
  };
}
