import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import Logo from "../../components/Logo";


interface Props {

}

const codes = {
    CONTACT: "https://vuedale.s3.us-east-2.amazonaws.com/Mohammed+Fanous.vcf",
    ["P"]: "https://vuedale.s3.us-east-2.amazonaws.com/Yannar+Tech+(2).pdf",
}

const Box = styled.div.attrs({ className: "bg-gray-200 w-48 font-semibold text-center self-center text-black uppercase p-2 mb-4 rounded-md" })``
export default function QR(props: Props) {
    const anchor = useRef(null);

    // useEffect(() => {
    //     console.log(anchor)
    //     anchor.current.click()

    //     return () => {
    //     }
    // }, [])

    return (
        <div className="text-white container-xl py-64 flex text-sm justify-center items-center flex-col">
            <Logo />
            <a
                className="mt-4"
                ref={anchor}
                href={codes["CONTACT"]}>
                <Box>
                    Mohammed Fanous
                </Box>
            </a>

            <a
                className=""
                ref={anchor}
                href={codes["P"]}>
                <Box>
                    Company Profile
                </Box>
            </a>
        </div>
    )
}