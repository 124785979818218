import _ from "lodash";
import React from "react";
import { compose, withProps } from "recompose";

import styles from "./styles.json";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";

const MyMapComponent = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyAqWdkZnXGRInLm54HK0arYAh91fJ8g2dY&v=3.exp",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `200px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)((props) => (
  <GoogleMap
    options={{
      styles: styles,
      scrollwheel: false,
      mapTypeControl: false,
      streetViewControl: false,
    }}
    defaultZoom={16}
    defaultCenter={{ lat: 31.955551, lng: 35.861709 }}
  >
    <Marker animation={true} position={{ lat: 31.955551, lng: 35.861709 }} />
  </GoogleMap>
));

export default function ReactGoogleMaps(props) {
  return _.identity(
    <div className="overflow-hidden rounded-sm">
      <MyMapComponent />
    </div>
  );
}
