import React from "react";
import Testimony from "../../../../lib/types/ITestimony";

interface Props {
  data: Testimony;
}

export default function (props: Props) {
  return (
    <div
      style={{
        zIndex: 100,
      }}
      className="flex justify-center"
    >
      <div className="rounded-full  border-2 border-primary-500">
        <div className="rounded-full h-12 w-12 overflow-hidden">
          <img src={`${props.data.picture}`} />
        </div>
      </div>
    </div>
  );
}
