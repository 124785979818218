export default [
  {
    name: "About us",
    path: "/about",
  },
  {
    name: "Objectives",
    path: "/objectives",
  },
  {
    name: "Solutions",
    path: "/solutions",
  },
  {
    name: "Gallery",
    path: "/gallery",
  },
  // {
  //   name: "Partners",‰
  //   path: "/partners",
  // },
];
