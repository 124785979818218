import React from "react";
import { useOutlet } from "react-router-dom";
import styled from "styled-components";
import Header from "./Header";
import MobileHeader from "./MobileHeader";
import Loading from "../../components/Loading";
import Footer from "./Footer";
export default function Main() {
  return (
    <div className="">
      <div className="hidden lg:block sticky">
        <Header />
      </div>
      <div className="block lg:hidden">
        <MobileHeader />
      </div>
      {useOutlet()}
      <div>
        <Footer />
      </div>
    </div>
  );
}
