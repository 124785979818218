import React from "react";
import { IoMdCheckmark } from "react-icons/io";
import { COLORS } from "../../lib/constants/Colors";

interface Props {
  text;
  color?;
  textClass?;
}

export default function (props: Props) {
  return (
    <div className="flex mt-2">
      <div>
        <IoMdCheckmark color={props.color ? props.color : COLORS.PRIMARY} />
      </div>
      <div className={`ml-2 ${props.textClass}`}>{props.text}</div>
    </div>
  );
}
