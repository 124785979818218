export default [
  {
    title: "Products",
    subtitle: "By Xano",
    description: "Delivery of packages from Sweden",
    image: "https://i.ibb.co/2s07CNj/8-01.jpg",
  },
  {
    title: "Servers",
    subtitle: "Amman",
    description: "Our servers in headquarters",
    image: "https://i.ibb.co/23jQ5m8/7-01.jpg",
  },
  {
    title: "5G",
    subtitle: "Coming Soon",
    description: "The network of the future",
    image: "https://i.ibb.co/mhjMC7g/6-01.jpg",
  },
  {
    title: "Cable Management",
    subtitle: "Cat6",
    description: "We thrive to deliver the best cable management solutions",
    image: "https://i.ibb.co/QQgtSMM/5-01.jpg",
  },
  {
    title: "Fiber Optics",
    subtitle: "F-122",
    description: "A demo render",
    image: "https://i.ibb.co/kVTqLW9/3-01.jpg",
  },
  {
    title: "Testing",
    subtitle: "A/B electric & Fiber",
    description: "Testing on demand, and regularly",
    image: "https://i.ibb.co/7G30dw6/2-01.jpg",
  },
];
