import React from "react";
import { log } from "util";
import Logo from "../../../components/Logo";
import SocialMedia from "../../../components/SocialMedia";
import styled from "styled-components";
import Map from "../../../components/Map";
import { map } from "lodash";
import { FiClock, FiMail, FiPhone, FiMapPin } from "react-icons/fi";
import { useNavigate } from "react-router";

const ListItem = styled.div.attrs({
  className:
    "flex items-center font-semibold text-xs text-white my-1 tracking-wide",
})``;
const SectionTitle = styled.div.attrs({
  className: "text-primary-500 font-bold text-lg",
})``;

const Section = styled.div.attrs({
  className:
    " lg:w-1/3 w-full flex flex-col  lg:w-1/2  h-full mb-6 lg:mb-12 justify-center items-center  lg:mb-0",
})``;

const SectionCenter = styled.div.attrs({
  className:
    "flex flex-grow-0 lg:mb-0  flex-col  justify-center items-center lg:justify-start lg:items-start",
})``;

interface Props { }

export default function Footer(props: Props) {
  const navigate = useNavigate();
  return (
    <div className="text-white border-t border-gray-800 relative overflow-hidden ">
      <div
        style={{ zIndex: -1, opacity: 0.1 }}
        className="w-64  w-full hidden lg:block absolute"
      >
        <img src={require("../../../assets/images/backgrounds/1.png")} />
      </div>
      <div
        style={{ zIndex: -1, opacity: 0.1 }}
        className="absolute w-64 absolute lg:hidden w-full"
      >
        <img src={require("../../../assets/images/backgrounds/2.png")} />
      </div>
      <div className="container-lg flex flex-col lg:flex-row overflow-hidden pt-12 justify-center items-start">
        <Section>
          <SectionCenter>
            <div className="flex justify-center items-center lg:justify-start lg:items-start">
              <Logo />
            </div>
            <div className="text-center lg:text-left text-sm whitespace-no-wrap mt-6 font-semibold">
              We are experienced professionals who <br />
              understand that services are changing <br />
              and are true partners who care about <br />
              your future business success <br />
            </div>
            <div className="mt-8 flex justify-center items-center lg:items-start lg:justify-start">
              <SocialMedia />
            </div>
          </SectionCenter>
        </Section>
        <Section>
          <SectionCenter>
            {/* {<Map />} */}
            <div className="text-primary-500 mb-2 text-lg uppercase font-bold ">
              Working Hours
            </div>
            <ListItem>
              <div className="mr-2">
                <FiClock color={"white"} size={15} />
              </div>
              Sun - Thu: 9:00 - 18:00
            </ListItem>
            <ListItem>
              <div className="mr-2">
                <FiClock color={"white"} size={15} />
              </div>
              Fri - Sat: Not operational
            </ListItem>
            <div className="mt-2">
              <ListItem>
                <div className="mr-2">
                  <FiMail color={"white"} size={15} />
                </div>
                info@yanartech.com
              </ListItem>
              <ListItem>
                <div className="mr-2 mt-2">
                  <FiPhone color={"white"} size={15} />
                </div>
                Tel: 065854082
              </ListItem>
            </div>
          </SectionCenter>
        </Section>
        <Section>
          <SectionCenter>
            <div className="text-primary-500 mb-2 text-lg uppercase font-bold ">
              Reach us
            </div>
            <ListItem>Amman, Jordan</ListItem>
            <ListItem>
              <div className="mr-2">
                <FiMapPin color={"white"} size={15} />
              </div>
              Prs. Taghrid St. 64 3
            </ListItem>
            <div className="text-primary-500 mt-4 mb-2 text-lg uppercase font-bold ">
              More
            </div>
            <div
              onClick={() => navigate("/store/terms_and_conditions")}
              className="cursor-pointer  text-xs mt-1 flex justify-center items-center">
              Terms & Conditions
            </div>
            <div
              onClick={() => navigate("/store/privacy_policy")}
              className="cursor-pointer  text-xs mt-1 flex justify-center items-center">
              Privacy Policy
            </div>
          </SectionCenter>
        </Section>
      </div>

      <div className="text-sm pb-4  text-center text-gray-600 ">
        Copyright © <span className="text-white">Yanartech </span> - All Rights
        Reserved 2020
      </div>
    </div>
  );
}
