import React from "react";
import RouteItem from "./RouteItem";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import MenuRoutes from "../../../lib/constants/MenuRoutes";
import Logo from "../../../components/Logo";
interface Props {}

export default function Header(props: Props) {
  const navigate = useNavigate();
  return (
    <div 
    style={{
      background:"rgb(10,10,10)"
    }}
    className=" border-b border-gray-800">
      <div className="flex justify-between items-center container-xl">
        <div 
        onClick={()=>navigate('')}
        className="w-1/4 cursor-pointer xl:w-1/3 p-4">
          <Logo />
        </div>
        <div className="w-3/4 xl:w-2/3 p-4">
          <div className="linksContainer flex items-center justify-end">
            {MenuRoutes.map((route) => (
              <RouteItem display={route.name} path={route.path} />
            ))}

            <div
              onClick={() => navigate("/contact")}
              className="ml-2  rounded-sm shadow bg-primary-500 py-1 px-3 rounded-sm text-white text-sm cursor-pointer font-bold whitespace-no-wrap"
            >
              Contact Us
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
