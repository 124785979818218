import React from "react";
import animationData from "../../assets/animations/loading.json";
import Lottie from "react-lottie";
import Logo from "../Logo";
export default class Loading extends React.Component {
  state = {
    isStopped: false,
    isPaused: false,
  };
  constructor(props) {
    super(props);
    this.state = { isStopped: false, isPaused: false };
  }

  render() {
    const buttonStyle = {
      display: "block",
      margin: "10px auto",
    };

    const defaultOptions = {
      loop: true,
      autoplay: true,
      animationData: animationData,
      rendererSettings: {
        preserveAspectRatio: "xMidYMid slice",
      },
    };

    return (
      <div
        style={{
          zIndex: 980123891278913912837,
        }}
        className="fixed inset-0 opacity-75 flex flex-col justify-center items-center bg-white"
      >
        <Lottie
          options={defaultOptions}
          height={400}
          width={400}
          isStopped={this.state.isStopped}
          isPaused={this.state.isPaused}
        />
        <div>
          <div className="text-4xl font-bold">
            <span className="text-primary-500">YANAR</span>{" "}
            <span className="">TECH</span>
          </div>
        </div>
      </div>
    );
  }
}
