import React from "react";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedin,
} from "react-icons/fa";
import { COLORS } from "../../lib/constants/Colors";

export default function () {
  const SocialList = [
    {
      icon: <FaFacebookF color={COLORS.PRIMARY} size={23} />,
      link: "",
    },
    {
      icon: <FaInstagram color={COLORS.PRIMARY} size={23} />,
      link: "",
    },
    {
      icon: <FaTwitter color={COLORS.PRIMARY} size={23} />,
      link: "",
    },
    {
      icon: <FaLinkedin color={COLORS.PRIMARY} size={23} />,
      link: "",
    },
  ];

  return (
    <div className="flex items-center">
      {SocialList.map((socialItem) => {
        return (
          <div
            style={{ borderWidth: 0.7 }}
            className=" mx-2 border-gray-500 rounded-full p-2"
          >
            {socialItem.icon}
          </div>
        );
      })}
    </div>
  );
}
