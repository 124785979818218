import React from "react";
import { BrowserRouter as Router, useRoutes } from "react-router-dom";
import routes from "./routes";

function RoutesRenderer({ routes }) {
  return useRoutes(routes);
}
function App() {
  return (
    <Router>
      <RoutesRenderer routes={routes} />
    </Router>
  );
}

export default App;
