import React from "react";

interface Props {
  data;
  right?;
}

export default function (props: Props) {
  return props.right ? (
    <div className="mb-8 w-full">
      <div className="h-16 w-16 mb-2 ml-auto">
        <img src={props.data.icon} className="h-full w-full" />
      </div>
      <div className="mb-2 text-right text-primary-500 font-semibold ">
        {props.data.title}
      </div>
      <div className="text-justify">{props.data.desc}</div>
    </div>
  ) : (
    <div className="mb-8">
      <div className="h-16 w-16 mb-2 -ml-2">
        <img src={props.data.icon} className="h-full w-full" />
      </div>
      <div className="mb-2 text-primary-500 font-semibold ">
        {props.data.title}
      </div>
      <div className="text-justify">{props.data.desc}</div>
    </div>
  );
}
