import React from "react";
import "./styles.css";
interface Props {
  src;
  content?;
  filter?;
}
export default function (props: Props) {
  return (
    <div>
      <div className=" banner-height relative overflow-hidden">
        <div
          style={{
            opacity: props.filter ? props.filter : 0,
          }}
          className="absolute bg-gray-900 zIndex-1 h-full w-full inset-0"
        ></div>
        <div
          className="absolute inset-0 tracking-wid  text-white font-bold text-xl h-full w-full flex justify-center sm:justify-start container-xl "
        >
          {props.content}
        </div>
        <div
          style={{
            backgroundImage: `url(${props.src})`,
            backgroundRepeat: "none",
            backgroundPosition: "center",
            backgroundSize: "cover",
            backgroundColor: "rgba(0,0,0,0.05)",
          }}
          className="w-full h-full"
        />
      </div>
    </div>
  );
}
